import React from 'react';

import { SocialIcon } from 'react-social-icons';

import './Home.css';

class Home extends React.Component {


  render() {

      return(
          <div className="container-home">
            <div className="principal-text">
              <h1 className="container-hi"> Hi, I am <span className="container-name">Agustí Conesa</span> </h1>
              <h2 className="container-description"> I am a Software Engineer. I am a creative person, hardworking, eager to learn new concepts and field experience.</h2>
              <div className="separator"></div>
              <div className="container-icons">
                <SocialIcon className="social-icon" url="https://www.linkedin.com/in/agust%C3%AD-conesa-45288b167/" />
                <SocialIcon className="social-icon-right" url="https://github.com/agusticonesagago" />
              </div>
            </div>
          </div>
        )
      }
}

export default Home;
