import React from 'react';

import aboutImage from '../../imagenes/aboutImage.jpg';
import webDevelopment from '../../imagenes/desarrollo-web.png';
import mobileApplication from '../../imagenes/telefono.png';
import analytics from '../../imagenes/analisis.png';

import './About.css';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {response: {}};
  }

    render() {

    return(

      <div className="container-general">
        <div className="content">
          <h1 className="title-section">ABOUT ME</h1>
          <hr className="separator-blue"/>
          <div className="container-about">
            <img src={aboutImage} alt="Photo About" className="photo-about"/>
            <div className="container-information-about">
              <h2 className="container-myName"> I am <span className="container-name"> Agustí Conesa</span> </h2>
              <div className="container-text"> I am a Software Engineer. I am a creative person, hardworking, eager to learn new concepts and field experience.</div>
              <div className="list-names-first"> <span className="question"> Full Name: </span> <span className="answer"> Agustí Conesa </span></div>
              <div className="list-names"> <span className="question"> Age: </span> <span className="answer"> 23 </span></div>
              <div className="list-names"> <span className="question"> Nationality: </span> <span className="answer"> Spanish </span></div>
              <div className="list-names"> <span className="question"> Languages: </span> <span className="answer"> Catalan, Spanish, English, French </span></div>
            </div>
          </div>
          <h2 className="title-section-space">SERVICES</h2>
          <hr className="separator-blue"/>
          <div className="container-services">
            <div className="container-service">
              <div className="line-blue"></div>
              <img src={webDevelopment} alt="Web Development" className="photo-icon"/>
              <div className="title-service">Web Development</div>
              <div className="title-service-explanation">I develop with ReactJS</div>
            </div>
            <div className="container-service">
              <div className="line-blue"></div>
              <img src={mobileApplication} alt="Web Development" className="photo-icon"/>
              <div className="title-service">Mobile Application</div>
              <div className="title-service-explanation">I develop with React Native</div>
            </div>
            <div className="container-service">
              <div className="line-blue"></div>
              <img src={analytics} alt="Web Development" className="photo-icon"/>
              <div className="title-service">Data Analytics and Science</div>
              <div className="title-service-explanation">I analyze and explore with Python</div>
            </div>
          </div>
        </div>

      </div>

      )
    }
}

export default About;
