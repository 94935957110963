import React from 'react';

import ProgressBar from '../ProgressBar/ProgressBar'
import suitcase from '../../imagenes/maleta-trabajo.png';
import book from '../../imagenes/libro.png';

import './Resume.css';

class Resume extends React.Component {

render() {
    return(
      <div className="container-general">
        <div className="content">
            <h1 className="title-section">MY SKILLS</h1>
            <hr className="separator-blue"/>
            <div className="container-about">
              <div className="container-section">
                <div className="container-language">
                  <div className="language"> ReactJS </div>
                  <div className="language-representation">
                    <span className="percentage"> 80% </span>
                    <div className="progressBar"><ProgressBar percentage={80}/></div>
                  </div>
                </div>
                <div className="container-language">
                  <div className="language"> React Native </div>
                  <div className="language-representation">
                    <span className="percentage"> 80% </span>
                    <div className="progressBar"><ProgressBar percentage={80}/></div>
                  </div>
                </div>
                <div className="container-language">
                  <div className="language"> C++ </div>
                  <div className="language-representation">
                    <span className="percentage"> 80% </span>
                    <div className="progressBar"><ProgressBar percentage={80}/></div>
                  </div>
                </div>
                <div className="container-language">
                  <div className="language"> Python </div>
                  <div className="language-representation">
                    <span className="percentage"> 75% </span>
                    <div className="progressBar"><ProgressBar percentage={75}/></div>
                  </div>
                </div>
                <div className="container-language">
                  <div className="language"> SQL </div>
                  <div className="language-representation">
                    <span className="percentage"> 75% </span>
                    <div className="progressBar"><ProgressBar percentage={75}/></div>
                  </div>
                </div>
                <div className="container-language">
                  <div className="language"> HTML </div>
                  <div className="language-representation">
                    <span className="percentage"> 75% </span>
                    <div className="progressBar"><ProgressBar percentage={75}/></div>
                  </div>
                </div>
                <div className="container-language">
                  <div className="language"> CSS </div>
                  <div className="language-representation">
                    <span className="percentage"> 75% </span>
                    <div className="progressBar"><ProgressBar percentage={75}/></div>
                  </div>
                </div>
                <div className="container-language">
                  <div className="language"> Javascript </div>
                  <div className="language-representation">
                    <span className="percentage"> 65% </span>
                    <div className="progressBar"><ProgressBar percentage={65}/></div>
                  </div>
                </div>
                <div className="container-language">
                  <div className="language"> Java </div>
                  <div className="language-representation">
                    <span className="percentage"> 65% </span>
                    <div className="progressBar"><ProgressBar percentage={65}/></div>
                  </div>
                </div>
                <div className="container-language">
                  <div className="language"> C </div>
                  <div className="language-representation">
                    <span className="percentage"> 65% </span>
                    <div className="progressBar"><ProgressBar percentage={65}/></div>
                  </div>
                </div>
                <div className="container-language">
                  <div className="language"> Android Studio </div>
                  <div className="language-representation">
                    <span className="percentage"> 65% </span>
                    <div className="progressBar"><ProgressBar percentage={65}/></div>
                  </div>
                </div>
              </div>
            </div>
            <h2 className="title-section-space-resume">RESUME</h2>
            <hr className="separator-blue"/>
            <div className="container-section">
              <div className="title-resume-icon">
                <img src={suitcase} alt="Working Experience" className="icon-resume"/>
                <div className="subtitle-resume">Working Experience</div>
              </div>
              <div className="container-line-working-experience">
                <div class="container">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div>
                  <div class="text-working-experience-first">
                      <div class="text-date">May 2017 - Jul 2020</div>
                      <div class="container-information-working-experience">
                        <div class="text-subtitle-working-experience">President</div>
                        <div class="text-info-working-experience">CEFIB</div>
                        <div class="text-info-explanation-working-experience">President of the IT Faculty Sports Club, whose main focus is to promote healthy life within the university environment. More than 100 participants in each event</div>
                      </div>
                  </div>
                  <div class="text-working-experience">
                      <div class="text-date">Mar 2019 - Jun 2019</div>
                      <div class="container-information-working-experience">
                        <div class="text-subtitle-working-experience">Front-end Developer</div>
                        <div class="text-info-working-experience">PartyAdvisor</div>
                        <div class="text-info-explanation-working-experience">Developed the webpage with ReactJS in a startup, where I had the opportunity of working with an agile methodology development.</div>
                      </div>
                  </div>
                  <div class="text-working-experience">
                      <div class="text-date">Sep 2018 - Jan 2019</div>
                      <div class="container-information-working-experience">
                        <div class="text-subtitle-working-experience">Student Mentor</div>
                        <div class="text-info-working-experience">Universitat Politècnica de Catalunya</div>
                        <div class="text-info-explanation-working-experience">I helped about fifteen freshmen in their first quadrimester to advise and guide helping them in their adaptation.</div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-section-last">
              <div className="title-resume-icon">
                <img src={book} alt="Educational Qualifications" className="icon-resume"/>
                <div className="subtitle-resume">Educational Qualifications</div>
              </div>
              <div className="container-line-working-experience">
                <div class="container">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div>
                  <div class="text-working-education-first">
                      <div class="text-date">Sep 2016 - Jan 2021</div>
                      <div class="container-information-working-experience">
                        <div class="text-subtitle-working-experience">Bachelor’s degree in Informatics Engineering at UPC</div>
                        <div class="text-info-working-experience">Barcelona School of Informatics (FIB) at Polytechnic University of Catalonia majored in Software Engineering.</div>
                        <div class="text-info-explanation-working-experience"></div>
                      </div>
                  </div>
                  <div class="text-working-experience-first-education">
                      <div class="text-date">Aug 2020 – Jan 2021</div>
                      <div class="container-information-working-experience">
                        <div class="text-subtitle-working-experience">Erasmus exchange program at Linnéuniversitet</div>
                        <div class="text-info-explanation-working-experience-big">Done the final degree project, focused on self-adaptive systems programmed in Python. More specifically, I created an ensemble method that works with incremental learning as to accurately predict resources, by taking into account possible peaks of demand.</div>
                      </div>
                  </div>
                  <div class="text-working-experience-first-education-last">
                      <div class="text-date">Sep 2014 - Jun 2016</div>
                      <div class="container-information-working-experience">
                        <div class="text-subtitle-working-experience">Technological Baccalaureate</div>
                        <div class="text-info-working-experience">Escola Infant Jesús - Jesuïtes st.Gervasi</div>
                        <div class="text-info-explanation-working-experience"></div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>

    )
 }
}

export default Resume;
