import React from 'react';

import { SocialIcon } from 'react-social-icons';
import uShare from '../../imagenes/uShare-logo.png';
import EDA_ApocalypseNow2018 from '../../imagenes/EDA_ApocalypseNow2018.png';
import SIM_Corral from '../../imagenes/SIM_Corral.jpg';
import miWeb from '../../imagenes/miWeb.png';
import modelitzacio3D from '../../imagenes/SIM_Modelitzacio_3D.jpg';
import modelitzacioProcessFlow from '../../imagenes/SIM_Modelitzacio_ProcessFlow.jpg';
import modelitzacioSimPy from '../../imagenes/SimPy.jpg';
import MatchPoint from '../../imagenes/logoMP.png';
import Chess from '../../imagenes/Chess.jpg';
import TFG from '../../imagenes/tfg.png';

import './Portfolios.css';

class Portfolios extends React.Component {
  render() {

    return(
      <div className="container-general">
        <div className="content">
          <h1 className="title-section">PORTFOLIOS</h1>
          <hr className="separator-blue"/>
          <div className="container-projects">
            <div className="container-project">
              <div className="image-project">
                      <img src={TFG} alt="Evaluation of methods to combine predictions from ensemble learning in multivariate forecasting" className="photo-project-sim"/>
                      <div class="overlay">
                        <SocialIcon className="icon-project" url="https://github.com/agusticonesagago/Evaluation-of-methods-to-combine-predictions-from-ensemble-learning-in-multivariate-forecasting"/>
                      </div>
              </div>
              <div className="text">
                <a class="link-title" href="https://github.com/agusticonesagago/Sardapp">
                  <div className="title-project-tfg">Evaluation of methods to combine predictions from ensemble learning in multivariate forecasting</div>
                </a>
                <div className="language-code-react-python">Python</div>
                <div className="title-project-explanation">My final degree project, focused on self-adaptive systems.</div>
              </div>
            </div>
            <div className="container-project">
              <div className="image-project">
                      <img src={uShare} alt="uShare" className="photo-project-uShare"/>
                      <div class="overlay">
                        <SocialIcon className="icon-project" url="https://github.com/agusticonesagago/Sardapp"/>
                      </div>
              </div>
              <div className="text">
                <a class="link-title" href="https://github.com/agusticonesagago/Sardapp">
                  <div className="title-project">uShare</div>
                </a>
                <div className="language-code-react-native">React Native</div>
                <div className="title-project-explanation">Application to meet people, see events and share transport.</div>
              </div>
            </div>
            <div className="container-project">
              <div className="image-project">
                      <div className="container-photo-project">
                        <img src={miWeb} alt="Portfolio Agustí Conesa" className="photo-project"/>
                      </div>
              </div>
              <div className="text">
                   <a class="link-title" href="#">
                    <div className="title-project">My Web Portfolio</div>
                  </a>
                  <div className="language-code-react-js">ReactJS</div>
                  <div className="title-project-explanation">My portfolio website.</div>
                </div>
              </div>
          <div className="container-project">
            <div className="image-project">
                    <img src={SIM_Corral} alt="Agent-based modeling" className="photo-project"/>
                    <div class="overlay">
                      <SocialIcon className="icon-project" url="https://github.com/agusticonesagago/SIM/tree/master/ABM"/>
                    </div>
            </div>
            <div className="text">
                <a class="link-title" href="https://github.com/agusticonesagago/SIM/tree/master/ABM">
                  <div className="title-project">Hen, rooster and egg ecosystem</div>
                </a>
                <div className="language-code-mesa">Mesa</div>
                <div className="title-project-explanation">Agent-based modeling of rooster and chicken life.</div>
              </div>
            </div>
            <div className="container-project">
              <div className="image-project">
                      <img src={modelitzacio3D} alt="3D disco modeling" className="photo-project-sim"/>
                      <div class="overlay">
                        <SocialIcon className="icon-project" url="https://github.com/agusticonesagago/SIM/tree/master/Modelitzaci%C3%B3%20amb%20Flexsim%20(objectes%203D)"/>
                      </div>
              </div>
              <div className="text">
                   <a class="link-title" href="https://github.com/agusticonesagago/SIM/tree/master/Modelitzaci%C3%B3%20amb%20Flexsim%20(objectes%203D)">
                    <div className="title-project">3D disco modeling</div>
                  </a>
                  <div className="language-code-flexsim">Flexsim</div>
                  <div className="title-project-explanation">Entrance and exit of a nightclub taking into account the process of leaving and collecting belongings in the locker room.</div>
              </div>
            </div>
            <div className="container-project">
              <div className="image-project">
                      <img src={modelitzacioSimPy} alt="Modeling a nightclub with process flow" className="photo-project-sim"/>
                      <div class="overlay">
                        <SocialIcon className="icon-project" url="https://github.com/agusticonesagago/SIM/tree/master/DES"/>
                      </div>
              </div>
              <div className="text">
                   <a class="link-title" href="https://github.com/agusticonesagago/SIM/tree/master/DES">
                    <div className="title-project">Discrete events simulator in a disco</div>
                  </a>
                  <div className="language-code-simpy">SimPy</div>
                  <div className="title-project-explanation">Entrance of a disco taking into account the drunk people and the entrance fee.</div>
              </div>
            </div>

            <div className="container-project">
              <div className="image-project">
                      <img src={MatchPoint} alt="MatchPoint" className="photo-project-MatchPoint"/>
                      <div class="overlay">
                        <SocialIcon className="icon-project" url="https://github.com/agusticonesagago/MatchPoint_front"/>
                      </div>
              </div>
              <div className="text">
                   <a class="link-title" href="https://github.com/agusticonesagago/MatchPoint_front">
                    <div className="title-project">MatchPoint</div>
                  </a>
                  <div className="language-code-android-studio">Android Studio</div>
                  <div className="title-project-explanation">Application to create and join sports activities near your location.</div>
              </div>
            </div>
            <div className="container-project">
              <div className="image-project">
                      <img src={Chess} alt="Chess" className="photo-project-chess"/>
                      <div class="overlay">
                        <SocialIcon className="icon-project" url="https://github.com/agusticonesagago/PROP_Chess"/>
                      </div>
              </div>
              <div className="text">
                   <a class="link-title" href="https://github.com/agusticonesagago/PROP_Chess">
                    <div className="title-project">Chess problem solver</div>
                  </a>
                  <div className="language-code-java">Java</div>
                  <div className="title-project-explanation">Environment that solves chess problems by white or black pieces.</div>
              </div>
            </div>
            <div className="container-project">
              <div className="image-project">
                      <img src={EDA_ApocalypseNow2018} alt="EDA_ApocalypseNow2018" className="photo-project"/>
                      <div class="overlay">
                        <SocialIcon className="icon-project" url="https://github.com/agusticonesagago/EDA_ApocalypseNow2018"/>
                      </div>
              </div>
              <div className="text">
                   <a class="link-title" href="https://github.com/agusticonesagago/EDA_ApocalypseNow2018">
                    <div className="title-project">Apocalypse Now Player</div>
                  </a>
                  <div class="container-code">
                    <div className="language-code-cplusplus">C++</div>
                    <div className="path-finding">Path finding</div>
                  </div>
                  <div className="title-project-explanation">A program that plays a game and plays against other student programs.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
    }
}

export default Portfolios;
