import React from 'react';

import './Sidebar.css';
import profile from '../../imagenes/profile.jpg';


const Sidebar = props => {
    let drawerClasses = 'side-drawer';
    if(props.show){
      drawerClasses = 'side-drawer open';
    }

    return(
      <div className={drawerClasses} style={{background:'#343838', float:'left', position: "fixed"}}>
          <div className="container-photo">
            <div className="separator"/>
            <div className="circular--landscape"> <img src={profile} alt="Photo Profile" className="photo-profile"/> </div>
            <div className="separator"/>
          </div>
          <hr className="line"/>
          <div className="container-options">
            <ul className="options">
              <div className="option" ><a href="/">HOME</a></div>
              <div className="option"><a href="/about">ABOUT</a></div>
              <div className="option"><a href="/resume">RESUME</a></div>
              <div className="option"><a href="/portfolios">PORTFOLIOS</a></div>
              <div className="option"><a href="/contact">CONTACT</a></div>
            </ul>
          </div>
      </div>
    );
};

export default Sidebar;
