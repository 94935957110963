import React, {useState, useEffect} from 'react';

import {useWindowScroll} from "react-use";
import { FaChevronUp } from 'react-icons/fa';

import './ScrollToTop.css';


const ScrollToTop = () => {
  const { y: pageYOffset } = useWindowScroll();
  const [visible, setVisibility] = useState(false);

  useEffect(() => {
    if(pageYOffset > 10){
      setVisibility(true);
    }else{
      setVisibility(false);
    }
  }, [pageYOffset]);

  const scrollToTop = () => window.scrollTo({top:0, behavior:"smooth"})

  if(!visible){
    return false;
  }
  return(
      <div className="scroll-to-top cursor-pointer text-center"
      onClick={scrollToTop}>
          <FaChevronUp className="icon-center"/>
      </div>
  )
}

export default ScrollToTop;
