import React, {useEffect} from 'react';
import {Router} from 'react-router-dom';

import Toolbar from './components/Toolbar/Toolbar'
import Sidebar from './components/Sidebar/Sidebar'
import Backdrop from './components/Backdrop/Backdrop'

import Routes from './routes/routes'
import history from './services/history'

import ScrollToTop from './components/ScrollToTop/ScrollToTop'

import {withRouter} from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);

require('dotenv').config();

class App extends React.Component {
  componentDidMount  = () => ReactGA.pageview(window.location.pathname + window.location.search);
  componentDidUpdate = () => ReactGA.pageview(window.location.pathname + window.location.search);

  constructor(props){
    super(props);
    this.state={
      sideDrawOpen: false,
      apiResponse: ''
    };
  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawOpen: !prevState.sideDrawOpen};
    });
  };

  backdropClickHandler = () =>{
    this.setState({sideDrawOpen:false});
  };


  render() {
    const styleFondo={
       background: '#10121b',
       position: 'absolute',
       top: 0,
       left: 0,
       right: 0,
       zIndex: 2,
       minWidth:'100%',
       minHeight:'100%',
    }
    let backdrop;

    if(this.state.sideDrawOpen){
      backdrop = <Backdrop click={this.backdropClickHandler}/>
    }
    return (
      <div style={styleFondo}>
        <Toolbar drawerClickHandler={this.drawerToggleClickHandler}/>
        <Sidebar show={this.state.sideDrawOpen}/>
        <ScrollToTop/>
        {backdrop}
        <Router history={history}>
          <Routes/>
        </Router>
      </div>

    )
  }
}

export default App;
