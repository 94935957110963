import React from 'react';

import Axios from 'axios';
import './Contact.css';
import mail from '../../imagenes/mail.png';

class Contact extends React.Component {
  constructor(props) {
  	super(props);
    this.state = {
        name: '',
        email: '',
        message: '',
        subject:'',
        disabled: false,
        emailSent: null,
        tryToSent: false,
    }
  }
  onNameChange(event) {
     this.setState({name: event.target.value})
  }
  onEmailChange(event) {
     this.setState({email: event.target.value})
  }
  onMessageChange(event) {
     this.setState({message: event.target.value})
  }
  onSubjectChange(event) {
     this.setState({subject: event.target.value})
  }

  sendMail() {
     this.setState({
         disabled: true
     });

     Axios.post('/api/email', this.state)
         .then(res => {
             if(res.data.success) {
                 this.setState({
                     disabled: false,
                     emailSent: true,
                     tryToSent: true
                 });
             } else {
                 this.setState({
                     disabled: false,
                     emailSent: false,
                     tryToSent: true
                 });
             }
             this.showAlert();
         })
         .catch(err => {
             console.log(err);

             this.setState({
                 disabled: false,
                 emailSent: false,
                 tryToSent: true
             });
             this.showAlert();
         })
  }

  showAlert(){
    if(this.state.emailSent && this.state.tryToSent){
      return(
        <div className="container-alert-correct">Successfully sent!</div>
      )
    }
    else if(this.state.tryToSent){
      return(
        <div className="container-alert-incorrect">The name, email adress, subject and message are required.</div>
      )
    }
    else return null;
  }

  render() {
    return(
      <div className="container-general">
        <div className="content">
          <h1 className="title-section">CONTACT ME</h1>
          <hr className="separator-blue"/>
          <div className="container-contact">
            <div className="container-contact-form" action="/contact" method="POST">
              <div className="form-group">
                	<label className="form-group-name">Name</label>
                	<input type="text" className="form-control" value={this.state.name} onChange={this.onNameChange.bind(this)} />
            	</div>
            	<div className="form-group">
                	<label className="form-group-email">Email address</label>
                	<input type="email" className="form-control" id="email"value={this.state.email} onChange={this.onEmailChange.bind(this)} />
            	</div>
              <div className="form-group">
                	<label className="form-group-subject">Subject</label>
                	<input type="subject" className="form-control" id="subject" value={this.state.subject} onChange={this.onSubjectChange.bind(this)} />
            	</div>
            	<div className="form-group">
                	<label className="form-group-message">Message</label>
                	<textarea className="form-control-message" rows="5" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
            	</div>
              <button className="button-send-mail" onClick={() => this.sendMail()}>SEND MAIL</button>
              {this.showAlert()}
            </div>
            <div className="container-contact-options">
              <div className="container-contact-email">
                <div className="container-email-border">
                  <img src={mail} alt="Mail" className="photo-icon-mail"/>
                </div>
                <div className="container-email-info">
                  <div className="container-email-title">Email</div>
                  <div className="container-email-text">agusticonesagago@gmail.com</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
    }
}

export default Contact;
