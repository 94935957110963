import React from 'react';
import {Switch, Route} from 'react-router-dom';

import Home from '../components/Home/Home'
import About from '../components/About/About'
import Resume from '../components/Resume/Resume'
import Portfolios from '../components/Portfolios/Portfolios'
import Contact from '../components/Contact/Contact'
import Sidebar from '../components/Sidebar/Sidebar'

export default function Routes() {
  return(
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/about" component={About} />
      <Route path="/resume" component={Resume} />
      <Route path="/portfolios" component={Portfolios} />
      <Route path="/contact" component={Contact} />
      <Route path="/sidebar" component={Sidebar} />
      <Route component={Home}/>
    </Switch>
  );
}
