import React from 'react';

import DrawerToggleButton from '../Sidebar/DrawerToggleButton';
import './Toolbar.css';

const toolbar = props => (
    <header className="toolbar">
      <nav className="toolbar__navigation">
        <div className="toolbar__toogle-button">
          <DrawerToggleButton click={props.drawerClickHandler}/>
        </div>
        <div className="spacer"></div>
        <div className="toolbar__navigation-items">
          <ul>
            <li><a href="/">HOME</a></li>
            <li><a href="/">ABOUT</a></li>
            <li><a href="/">RESUME</a></li>
            <li><a href="/">PORTFOLIOS</a></li>
            <li><a href="/">CONTACT</a></li>
          </ul>
        </div>

      </nav>
    </header>
);

export default toolbar;
